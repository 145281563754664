import React, { useContext, useEffect, useState } from 'react';
import * as R from 'ramda';

import { ITEM_CODE_ATTRIBUTE_NAME } from '@atom/components/common/requests/assetRequestConstants';
import { getAttributeFilterByName } from '@atom/components/common/requests/customTenantUtilities';
import UsersFilter from '@atom/components/common/usersFilter/UsersFilter';
import { GET_ASSETS } from '@atom/graph/asset';
import client from '@atom/graph/client';
import { SelectAutocomplete, TextField } from '@atom/mui';
import colors from '@atom/styles/colors';
import { AssetRequestCreateInput } from '@atom/types/assetRequest';
import {
  AssetBudget,
  AssetConnectionItem,
  AssetsConnection,
  AssetsConnectionInput,
} from '@atom/types/inventory';

import RequestsContext from '../../RequestsContext';

import './sddotCreate.css';

const ASSET_LIMIT = 250;

const styles = {
  label: {
    color: colors.neutral.dim,
  },
};

interface Props {
  budget: AssetBudget;
  createInputs: AssetRequestCreateInput;
  updateCreateInput: (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => void;
  selectedFromAsset: AssetConnectionItem;
  setSelectedFromAsset: (asset: AssetConnectionItem) => void;
}

const SDDOTInventoryCreate = ({
  budget,
  createInputs,
  updateCreateInput,
  selectedFromAsset,
  setSelectedFromAsset,
}: Props) => {
  const { asset } = useContext(RequestsContext);

  const [assets, setAssets] = useState<AssetConnectionItem[]>([]);
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false);

  const getAssetPage = (page: number): Promise<AssetConnectionItem[]> => {
    const itemCodeAttributeFilter = getAttributeFilterByName(
      asset,
      ITEM_CODE_ATTRIBUTE_NAME,
    );

    return client
      .query<{ assets: AssetsConnection }, { input: AssetsConnectionInput }>({
        query: GET_ASSETS,
        variables: {
          input: {
            page,
            limit: ASSET_LIMIT,
            attributes: itemCodeAttributeFilter,
            omitIds: [asset.id],
          },
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => data.assets.assets);
  };

  useEffect(() => {
    // Batch load all assets upfront
    const retrieveAssets = async () => {
      setLoadingAssets(true);

      const itemCodeAttributeFilter = getAttributeFilterByName(
        asset,
        ITEM_CODE_ATTRIBUTE_NAME,
      );

      const { data } = await client.query<
        { assets: AssetsConnection },
        { input: AssetsConnectionInput }
      >({
        query: GET_ASSETS,
        variables: {
          input: {
            page: 1,
            limit: ASSET_LIMIT,
            attributes: itemCodeAttributeFilter,
            omitIds: [asset.id],
          },
        },
        fetchPolicy: 'network-only',
      });

      let totalAssets = data.assets.assets;
      const total = Number(data.assets.totalCount);

      if (total > ASSET_LIMIT) {
        const pages = Math.ceil(total / ASSET_LIMIT);
        const promises: Array<Promise<AssetConnectionItem[]>> = [];

        for (let index = 2; index <= pages; index++) {
          promises.push(getAssetPage(index));
        }

        const allRemainingAssets = await Promise.all(promises);
        totalAssets = [...totalAssets, ...R.flatten(allRemainingAssets)];
      }

      setAssets(totalAssets);
      setLoadingAssets(false);
    };

    retrieveAssets();
  }, []);

  const handleAssetSelect = (newAsset: AssetConnectionItem) => {
    setSelectedFromAsset(newAsset);
    updateCreateInput(newAsset?.id, 'fromAssetId');
  };

  const numberUpdate = (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => {
    const updatedValue =
      Math.abs(Number(value)) > 0 ? Math.abs(Number(value)) : null;
    updateCreateInput(updatedValue, property);
  };

  const renderOption = (option: AssetConnectionItem) => {
    return (
      <div>
        <div>{option.name}</div>
        <div styleName="asset-option-subtext">{`${option.budget.quantityOnHand} ${option.budget.unit} in stock`}</div>
      </div>
    );
  };

  const requestedQuantityError =
    Number(selectedFromAsset?.budget?.quantityOnHand) -
      Number(createInputs?.quantityOrdered) <
    0;

  return (
    <>
      <div styleName="input-container">
        <SelectAutocomplete
          id="fromAssetId"
          label="Transfer From"
          options={assets}
          value={selectedFromAsset}
          onChange={(_, newValue) => handleAssetSelect(newValue)}
          renderOption={renderOption}
          getOptionLabel={option => option.name}
          disabled={loadingAssets}
          loading={loadingAssets}
          InputLabelProps={{ style: styles.label }}
          blurOnSelect
        />
        {selectedFromAsset && (
          <div styleName="subtext selected-asset">{`${selectedFromAsset?.budget?.quantityOnHand} ${selectedFromAsset?.budget?.unit} in stock`}</div>
        )}
      </div>
      <div styleName="input-container">
        <div styleName="subtext title">Transfer To</div>
        <div>{asset?.name}</div>
        <div styleName="subtext">{`${budget?.quantityOnHand} ${budget?.unit} in stock`}</div>
      </div>
      <div styleName="input-container">
        <TextField
          InputLabelProps={{ style: styles.label }}
          type="number"
          value={createInputs?.quantityOrdered || ''}
          label={`Requested Quantity (${budget?.unit})`}
          onChange={event =>
            numberUpdate(event.target.value, 'quantityOrdered')
          }
          helperText={
            requestedQuantityError && 'Exceeds available stock levels'
          }
          error={requestedQuantityError}
        />
      </div>
      {createInputs?.quantityOrdered && (
        <div styleName="quantity-transfer-subtext">
          The unit cost of this inventory item will be re-calculated by taking
          into account the approved quantity and the unit cost of the inventory
          it was transferred from.
        </div>
      )}
      <div styleName="input-container">
        <UsersFilter
          value={createInputs?.assignedTo}
          updateValue={newValue => updateCreateInput(newValue, 'assignedTo')}
          placeholder="Search users"
          label="Assigned To"
          showTitle
        />
      </div>
    </>
  );
};

export default SDDOTInventoryCreate;
