import React, { useContext } from 'react';

import RequestsTableRow from '@atom/components/common/requests/requestsTable/RequestsTableRow';
import { ListTable } from '@atom/mui';

import RequestsContext from '../RequestsContext';

import RequestsTableHeader from './RequestsTableHeader';

const { TableRow, TableBody, TableFooter, TablePagination } = ListTable;

const RequestsTable = () => {
  const {
    requests,
    totalCount,
    page,
    setPage,
    limit,
    setLimit,
    filters,
    setActiveRequestId,
    asset,
  } = useContext(RequestsContext);

  return (
    <ListTable offsetTop="325px">
      <RequestsTableHeader />
      <TableBody>
        {requests.map(request => (
          <RequestsTableRow
            key={request.id}
            request={request}
            filters={filters}
            setActiveRequestId={setActiveRequestId}
            assetId={asset?.id}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, 250]}
            count={totalCount}
            rowsPerPage={limit}
            page={page}
            onPageChange={value => setPage(value)}
            onRowsPerPageChange={event => {
              setPage(1);
              setLimit(+event.target.value);
            }}
          />
        </TableRow>
      </TableFooter>
    </ListTable>
  );
};

export default RequestsTable;
