import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import * as R from 'ramda';
import { StringParam, useQueryParams } from 'use-query-params';

import {
  INITIAL_IS_ASCENDING,
  INITIAL_LIMIT,
  INITIAL_PAGE,
  INITIAL_SORT_BY,
} from '@atom/components/common/requests/assetRequestConstants';
import {
  cleanFilters,
  getCustomValues,
  getTypeChangeFilters,
} from '@atom/components/common/requests/customTenantUtilities';
import ViewRequestModal from '@atom/components/common/requests/viewRequest/ViewRequestModal';
import { GET_ASSET_REQUESTS } from '@atom/graph/assetRequest';
import { GET_SCHEMA } from '@atom/graph/schema';
import { Progress } from '@atom/mui';
import {
  AssetRequestsConnection,
  AssetRequestsConnectionInput,
  AssetRequestsFilters,
  AssetRequestType,
} from '@atom/types/assetRequest';
import {
  InventoryAssetDetailType,
  InventorySchemaItem,
} from '@atom/types/inventory';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RequestsFilters from './requestsFilters/RequestsFilters';
import RequestsTable from './requestsTable/RequestsTable';
import RequestsContext from './RequestsContext';
import RequestsHeader from './RequestsHeader';

import './requests.css';

interface Props {
  asset: InventoryAssetDetailType;
}

const RequestsTab = ({ asset }: Props) => {
  const [queryParams, setQueryParams] = useQueryParams({
    activeRequestId: StringParam,
  });

  const [schema, setSchema] = useState<InventorySchemaItem>();
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [limit, setLimit] = useState<number>(INITIAL_LIMIT);
  const [sortBy, setSortBy] = useState<string>(INITIAL_SORT_BY);
  const [isAscending, setIsAscending] = useState<boolean>(INITIAL_IS_ASCENDING);
  const [filters, setFilters] = useState<AssetRequestsFilters>({});
  const [filtersCart, setFiltersCart] = useState<AssetRequestsFilters>({});

  const [getSchema, { loading: loadingSchema }] = useLazyQuery<
    { schema: InventorySchemaItem },
    { id: string }
  >(GET_SCHEMA, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setSchema(data?.schema || null);
      const { initialFilters } = getCustomValues(data?.schema, asset);
      setFilters(initialFilters);
      setFiltersCart(initialFilters);
    },
  });

  useEffect(() => {
    if (!isNilOrEmpty(asset)) {
      getSchema({
        variables: {
          id: asset?.schemaId,
        },
      });
    }
  }, [asset]);

  const setActiveRequestId = (id: string) => {
    setQueryParams({ activeRequestId: id });
  };

  const resetInitialValues = () => {
    setPage(INITIAL_PAGE);
    setLimit(INITIAL_LIMIT);
    setSortBy(INITIAL_SORT_BY);
    setIsAscending(INITIAL_IS_ASCENDING);
  };

  const resetFilters = () => {
    const { initialFilters } = getCustomValues(schema, asset);
    resetInitialValues();
    setFilters(initialFilters);
    setFiltersCart(initialFilters);
  };

  const {
    data,
    loading: assetRequestsLoading,
    refetch: refetchAssetRequests,
  } = useQuery<
    { assetRequests: AssetRequestsConnection },
    { input: AssetRequestsConnectionInput }
  >(GET_ASSET_REQUESTS, {
    variables: {
      input: {
        page,
        limit,
        sortBy,
        isAscending,
        ...filters,
      },
    },
    fetchPolicy: 'network-only',
  });

  // When type is changed, filters should be automatically applied.
  const changeType = (type: AssetRequestType) => {
    const updatedFilter = getTypeChangeFilters(type, asset);

    // Handles refetching if a new request is created without changing types.
    if (type === filters?.type) {
      refetchAssetRequests();
    }

    setFiltersCart(updatedFilter);
    setFilters(cleanFilters(updatedFilter));
  };

  const applyFilters = () => {
    resetInitialValues();
    setFilters(cleanFilters(filtersCart));
  };

  const totalCount = R.pathOr(0, ['assetRequests', 'totalCount'], data);
  const requests = R.pathOr([], ['assetRequests', 'assetRequests'], data);

  return (
    <RequestsContext.Provider
      value={{
        asset,
        schema,
        loadingSchema,
        requests,
        totalCount,
        loading: assetRequestsLoading,
        filters,
        filtersCart,
        setFiltersCart,
        page,
        setPage,
        limit,
        setLimit,
        sortBy,
        setSortBy,
        isAscending,
        setIsAscending,
        changeType,
        setActiveRequestId,
      }}
    >
      <>
        <div styleName="body-container">
          <div styleName="left-body-pane-fixed">
            <RequestsFilters
              applyFilters={applyFilters}
              resetFilters={resetFilters}
            />
          </div>
          <div styleName="right-body-pane-fill">
            <RequestsHeader />
            {assetRequestsLoading ? (
              <div styleName="progress-container">
                <Progress />
              </div>
            ) : (
              <RequestsTable />
            )}
          </div>
        </div>
        <ViewRequestModal
          activeRequestId={queryParams.activeRequestId}
          setActiveRequestId={setActiveRequestId}
          changeType={changeType}
        />
      </>
    </RequestsContext.Provider>
  );
};

export default RequestsTab;
