import React, { useContext, useEffect, useMemo, useState } from 'react';

import { TYPE_LABELS } from '@atom/components/common/requests/assetRequestConstants';
import { getCustomValues } from '@atom/components/common/requests/customTenantUtilities';
import { GET_ASSET } from '@atom/graph/asset';
import client from '@atom/graph/client';
import { Modal, Select } from '@atom/mui';
import colors from '@atom/styles/colors';
import {
  AssetRequestCreateInput,
  AssetRequestType,
} from '@atom/types/assetRequest';
import {
  AssetBudget,
  AssetConnectionItem,
  AssetDetail,
} from '@atom/types/inventory';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import RequestsContext from '../RequestsContext';

import SDDOTAdjustmentCreate from './sddotCreate/SDDOTAdjustmentCreate';
import SDDOTInventoryCreate from './sddotCreate/SDDOTInventoryCreate';
import SDDOTReplenishmentCreate from './sddotCreate/SDDOTReplenishmentCreate';
import SDDOTStockpileCreate from './sddotCreate/SDDOTStockpileCreate';

const { MenuItem } = Select;

const styles = {
  modal: {
    padding: '1.5rem',
  },
  select: {
    maxWidth: '28.25rem',
  },
};

interface Props {
  open: boolean;
  onClose: () => void;
  createInputs: AssetRequestCreateInput;
  updateCreateInput: (
    value: any,
    property: keyof AssetRequestCreateInput,
  ) => void;
  onConfirm: () => void;
  updateType: (type: any) => void;
}

const CreateRequestModal = ({
  open,
  onClose,
  createInputs,
  updateCreateInput,
  onConfirm,
  updateType,
}: Props) => {
  const { schema, asset } = useContext(RequestsContext);

  const [budget, setBudget] = useState<AssetBudget>();
  const [budgetLoading, setBudgetLoading] = useState<boolean>(false);
  const [selectedFromAsset, setSelectedFromAsset] = useState<
    AssetConnectionItem
  >();

  const getBudget = async () => {
    setBudgetLoading(true);
    const { data } = await client.query<{ asset: AssetDetail }, { id: string }>(
      {
        query: GET_ASSET,
        fetchPolicy: 'network-only',
        variables: {
          id: asset.id,
        },
      },
    );

    setBudget(data?.asset?.budget);
    setBudgetLoading(false);
  };

  useEffect(() => {
    if (open) {
      getBudget();
    }
  }, [open]);

  useEffect(() => {
    setSelectedFromAsset(null);
  }, [createInputs.type]);

  const createProps = {
    open,
    createInputs,
    updateCreateInput,
    budget,
  };

  const modalContent = {
    [AssetRequestType.SDDOT_INVENTORY_TRANSFER]: (
      <SDDOTInventoryCreate
        {...createProps}
        selectedFromAsset={selectedFromAsset}
        setSelectedFromAsset={setSelectedFromAsset}
      />
    ),
    [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]: (
      <SDDOTStockpileCreate
        {...createProps}
        selectedFromAsset={selectedFromAsset}
        setSelectedFromAsset={setSelectedFromAsset}
      />
    ),
    [AssetRequestType.SDDOT_REPLENISHMENT]: (
      <SDDOTReplenishmentCreate {...createProps} />
    ),
    [AssetRequestType.SDDOT_ADJUSTMENT]: (
      <SDDOTAdjustmentCreate {...createProps} />
    ),
  };

  const disabled = useMemo(() => {
    const {
      replenishment,
      quantityOrdered,
      quantity,
      reason,
      fromAssetId,
      assignedTo,
    } = createInputs;

    const inventoryTransferDifference =
      Number(selectedFromAsset?.budget?.quantityOnHand) -
      Number(quantityOrdered);
    const stockpileTransferDifference =
      Number(selectedFromAsset?.budget?.quantityOnHand) - Number(quantity);
    const adjustmentUpdatedQuantity =
      Number(asset?.budget?.quantityOnHand) + Number(quantity);

    const disabledRules = {
      [AssetRequestType.SDDOT_INVENTORY_TRANSFER]:
        isNilOrEmpty(fromAssetId) ||
        isNilOrEmpty(quantityOrdered) ||
        inventoryTransferDifference < 0 ||
        isNilOrEmpty(assignedTo),
      [AssetRequestType.SDDOT_STOCKPILE_TRANSFER]:
        isNilOrEmpty(fromAssetId) ||
        isNilOrEmpty(quantity) ||
        stockpileTransferDifference < 0,
      [AssetRequestType.SDDOT_REPLENISHMENT]:
        isNilOrEmpty(quantityOrdered) ||
        isNilOrEmpty(replenishment?.invoiceNumber) ||
        isNilOrEmpty(replenishment?.quantityReceived) ||
        isNilOrEmpty(replenishment?.unitCost) ||
        isNilOrEmpty(replenishment?.totalCost),
      [AssetRequestType.SDDOT_ADJUSTMENT]:
        isNilOrEmpty(quantity) ||
        adjustmentUpdatedQuantity < 0 ||
        isNilOrEmpty(reason),
    };

    return disabledRules[createInputs?.type];
  }, [createInputs]);

  const { typeOptions } = getCustomValues(schema, asset);

  const modalWidth =
    createInputs?.type === AssetRequestType.SDDOT_REPLENISHMENT ? 'xl' : 'sm';

  return (
    <Modal
      title="Create Inventory Request"
      open={open}
      onCancel={onClose}
      onConfirm={onConfirm}
      confirmButtonText="Create"
      data-cy="createAssetRequest"
      contentStyle={styles.modal}
      disabled={disabled}
      width={modalWidth}
      loading={budgetLoading}
      disableCloseOnOutsideClick
    >
      <div>
        <Select
          key="type"
          label="Request Type"
          style={styles.select}
          InputLabelProps={{ style: { color: colors.neutral.dim } }}
          fullWidth
          onChange={event => updateType(event.target.value)}
          value={createInputs.type}
        >
          {typeOptions.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {TYPE_LABELS[option]}
            </MenuItem>
          ))}
        </Select>
        {modalContent[createInputs.type]}
      </div>
    </Modal>
  );
};

export default CreateRequestModal;
